<template>
   <div class="obi-content">
      <div class="obi-content--sidebar">
         <slot name="sidebar"></slot>
      </div>
      <div class="obi-content--content">
         <slot name="content"></slot>
      </div>
   </div>
</template>

<script>
export default {
   name: 'ObiContent',
   props: {
      isAdmin: {
         type: [Boolean],
         default: false,
      },
   },
};
</script>
<style lang="scss" scoped>
@media (min-width: 1240px) {
   .obi-content {
      max-width: 1240px;
   }
}

.obi-content {
   background-color: #ffffff;
   grid-template-columns: auto 1fr;
   -webkit-box-flex: 1;
   flex-grow: 1;
   width: 100%;
   box-sizing: border-box;
   margin: 0px auto;

   .obi-content--sidebar {
      background-color: #ffffff;
   }
}
</style>
